import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { gqlMongoByKey, gqlMongoCount, gqlMongoLoad, } from '../../../../shared/classes/loopback-custom-store/generic/store.utils';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { StateStoreService } from '../../../../shared/modules/my-common/services/state-store.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { LoggerService, MyUser } from '../../../../shared/sdk';
import { FullNamePipe } from 'src/app/shared/modules/ui/pipes/full-name.pipe';
export class GMapLogComponent extends ABaseComponent {
    constructor(logger, config, common, ui, sss, dss, gridHelper) {
        super(logger);
        this.logger = logger;
        this.config = config;
        this.common = common;
        this.ui = ui;
        this.sss = sss;
        this.dss = dss;
        this.gridHelper = gridHelper;
        this.calculateYesFilterExpression = function (val, op, target) {
            return [this.dataField, ((op === '<>' || op === 'notcontains') && '<>') || '=', 'yes'.includes(val.toLowerCase())];
        };
        this.params_calculateValue = row => JSON.stringify(row.params);
        this.result_calculateValue = row => JSON.stringify(row.result);
        this.username_calculateValue = row => this.usersMap[row.userId];
        this.grid_stateStoring = {
            enabled: true,
            type: 'localStorage',
            storageKey: '7776d037-26fa-4e37-ab42-14076ee1b4b9',
        };
    }
    ngOnInit() {
        const _super = Object.create(null, {
            ngOnInit: { get: () => super.ngOnInit }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            _super.ngOnInit.call(this);
            this.usersMap = yield this.buildUsersMap();
            this.dso = this.buildDataSource();
        });
    }
    grid_onInitialized(e) { }
    grid_onToolbarPreparing(e) {
        e.toolbarOptions.items.push(Object.assign({ name: 'refresh', locateInMenu: 'auto', location: 'after', widget: 'dxButton', showText: 'inMenu' }, { options: { icon: 'refresh', text: 'Refresh', hint: 'Refresh', onClick: () => e.component.refresh() } }));
    }
    ngAfterViewInit() { }
    buildUsersMap() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const users = yield this.dss
                .getApi(MyUser)
                .find({
                where: {},
                include: [{ employee: ['person'] }],
            })
                .toPromise();
            return users.reduce((p, u) => (Object.assign({}, p, { [u.id]: new FullNamePipe(this.config).transform(u.employee) || u.username })), {});
        });
    }
    buildDataSource() {
        const self = this;
        const col = 'GMapLog';
        const aggregate = [{ $sort: { time: -1 } }];
        const store = new CustomStore({
            useDefaultSearch: true,
            cacheRawData: false,
            load: (loadOptions) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return gqlMongoLoad(self.dss, col, loadOptions, aggregate).toPromise();
            }),
            byKey: (key) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return gqlMongoByKey(self.dss, col, key).toPromise();
            }),
            totalCount: (loadOptions) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                return gqlMongoCount(self.dss, col, loadOptions, aggregate).toPromise();
            }),
        });
        return { store };
    }
}
