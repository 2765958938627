<div class="filter-panel">
  <dx-date-box #from width="100px" [(value)]="selectedDate" (onValueChanged)="calendar_onValueChanged($event)">
  </dx-date-box>

  <label>Order</label>
  <dx-select-box
    [dataSource]="[
      { key: 'least', value: 'Least Busy First' },
      { key: 'most', value: 'Most Busy First' }
    ]"
    displayExpr="value"
    valueExpr="key"
    [(value)]="dataSourceOrder"
    (onValueChanged)="sortDataSourceHandler($event)"></dx-select-box>

  <label>Selected Vehicles</label>
  <dx-tag-box
    width="150px"
    [dataSource]="vehicles"
    searchEnabled="true"
    showSelectionControls="true"
    displayExpr="internalId"
    valueExpr="id"
    [(value)]="selectedVehicleIds"
    (onValueChanged)="selectedVehiclesChangeHandler($event)"
    maxDisplayedTags="3"></dx-tag-box>

  <label *ngIf="shouldShowKeepManifestTrips()">Keep Manifest Trips</label>
  <dx-switch *ngIf="shouldShowKeepManifestTrips()" [(value)]="keepManifestTrips" onText="Yes" offText="No"></dx-switch>
  <label>Help slim crews</label>
  <dx-switch [(value)]="withAvoid" onText="Yes" offText="No"></dx-switch>
  <label>Allow favorites</label>
  <dx-switch [(value)]="withPreferred" onText="Yes" offText="No"></dx-switch>
  <dx-button
    text="Propose Trips"
    (onClick)="proposeHandler()"
    [disabled]="!selectedVehicleIds.length || !selectedDate"></dx-button>

  <dx-button
    text="Calculate Travel"
    (onClick)="calculateTravelHandler()"
    [disabled]="!selectedVehicleIds.length || !selectedDate"></dx-button>

  <dx-button
    [text]="lockSelectedTrips ? 'Clear Selected' : 'Lock First Trips'"
    (onClick)="lockFirstTripsOrClearAllHandler()"
    [disabled]="!selectedVehicleIds.length || !selectedDate"></dx-button>

  <label>Show Work Time</label>
  <dx-switch [(value)]="showWorkTime" onText="Yes" offText="No"></dx-switch>

  <span *ngIf="numberOfTripsLost" class="badge-small badge-orange">
    Number of Trips Lost: {{ numberOfTripsLost }}
  </span>

  <button class="small-button" title="Open Weekly Hours" (click)="openWorkingHours()">
    <i class="fa fa-clock"></i> Weekly Hours
  </button>
</div>

<mat-card>
  <mat-card-content>
    <div class="card-content">
      <div>
        <div class="card-content-header">
          <div>
            <span *ngIf="getSchedulingConflicts()" class="badge badge-pink">
              Scheduling Conflicts {{ getSchedulingConflicts() }}</span
            >
          </div>
          <div>
            <span class="badge badge-blue"> Total Vehicles {{ totals.manifest.totalVehicles }}</span>
            <span class="badge badge-blue">
              Combined Travel {{ getHours(totals.manifest.combinedTravelMinutes) }}h,
              {{ distanceInMiles(totals.manifest.combinedTravelDistance) }}mi
            </span>
            <span class="badge badge-blue">
              Combined Loaded {{ getHours(totals.manifest.combinedLoadedMinutes) }}h,
              {{ distanceInMiles(totals.manifest.combinedLoadedDistance) }}mi
            </span>
            <span class="badge badge-blue">
              Combined Free Time {{ getHours(totals.manifest.combinedFreeMinutes) }}h
            </span>
            <span class="badge badge-blue"> Total Number of Trips {{ totals.manifest.totalNumberOfTrips }}</span>
            <!-- <button class="small-button" (click)="exportGroups('manifestGroup')" title="Export Manifest">
              <i class="fas fa-file-excel"></i>
            </button> -->
          </div>
        </div>
      </div>

      <div>
        <div class="card-content-header">
          <div></div>
          <div>
            <button *ngIf="totals.proposed.totalVehicles" class="small-button" (click)="overwriteAllManifestHandler()">
              <i class="fa fa-arrow-left"></i>
            </button>
            <span class="badge badge-blue"> Total Vehicles {{ totals.proposed.totalVehicles }}</span>
            <span class="badge badge-blue">
              Combined Travel {{ getHours(totals.proposed.combinedTravelMinutes) }}h,
              {{ distanceInMiles(totals.proposed.combinedTravelDistance) }}mi
            </span>
            <span class="badge badge-blue">
              Combined Loaded {{ getHours(totals.proposed.combinedLoadedMinutes) }}h,
              {{ distanceInMiles(totals.proposed.combinedLoadedDistance) }}mi
            </span>
            <span class="badge badge-blue">
              Combined Free Time {{ getHours(totals.proposed.combinedFreeMinutes) }}h
            </span>
            <span class="badge badge-blue"> Total Number of Trips {{ totals.proposed.totalNumberOfTrips }}</span>
            <!-- <button class="small-button" (click)="exportGroups('proposedGroup')" title="Export Proposed">
              <i class="fas fa-file-excel"></i>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<dx-scroll-view>
  <div *ngFor="let data of dataSource; let dataSourceIdx = index">
    <mat-card>
      <mat-card-content>
        <div class="card-content">
          <div>
            <div *ngIf="data.manifestGroup">
              <div class="card-content-header">
                <ng-container
                  *ngTemplateOutlet="
                    vehTemplate;
                    context: { group: data.manifestGroup, altGroup: data.proposedGroup }
                  "></ng-container>
                <ng-container
                  *ngTemplateOutlet="
                    gridHeaderTemplate;
                    context: { group: data.manifestGroup, dataSourceIdx: dataSourceIdx }
                  "></ng-container>
              </div>
              <ng-container
                *ngTemplateOutlet="gridTemplate; context: { trips: data.manifestGroup?.trips }"></ng-container>
              <ng-container
                *ngTemplateOutlet="gridFooterTemplate; context: { group: data.manifestGroup }"></ng-container>
            </div>
          </div>

          <div>
            <div *ngIf="data.proposedGroup">
              <div class="card-content-header">
                <ng-container
                  *ngTemplateOutlet="
                    vehTemplate;
                    context: { group: data.proposedGroup, isPropose: true, altGroup: data.manifestGroup }
                  "></ng-container>
                <ng-container
                  *ngTemplateOutlet="
                    gridHeaderTemplate;
                    context: { group: data.proposedGroup, isPropose: true, dataSourceIdx: dataSourceIdx }
                  "></ng-container>
              </div>
              <ng-container
                *ngTemplateOutlet="
                  gridTemplate;
                  context: { trips: data.proposedGroup.trips, isPropose: true }
                "></ng-container>
              <ng-container
                *ngTemplateOutlet="
                  gridFooterTemplate;
                  context: { group: data.proposedGroup, isPropose: true }
                "></ng-container>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</dx-scroll-view>

<!--  Template -->
<ng-template #vehTemplate let-group="group" let-isPropose="isPropose" let-altGroup="altGroup">
  <div>
    <span class="badge badge-with-remove" *ngIf="group.vehicle">
      Veh #{{ group.vehicle.internalId }}
      <button class="badge-remove-button" (click)="removeVehicleHandler(group, altGroup)">
        <i class="fa fa-times"></i>
      </button>
    </span>
    <ng-container *ngIf="!group.vehicle && group.trips?.length">
      <span class="badge badge-red"> Unassigned Trips</span>
      <dx-select-box
        *ngIf="vehiclesAvailable.length"
        width="100px"
        [dataSource]="vehiclesAvailable"
        displayExpr="internalId"
        valueExpr="id"
        placeholder="Vehicle"
        (onValueChanged)="assignVehicleHandler(group, altGroup, $event.value)">
      </dx-select-box>
    </ng-container>

    <span class="badge badge-with-remove" *ngIf="group.employee">
      Drv. {{ employeeHelper.displayExpr(group.employee) }}
      <button class="badge-remove-button" (click)="removeEmployeeHandler(group, altGroup)">
        <i class="fa fa-times"></i>
      </button>
    </span>
    <ng-container *ngIf="!group.employee && group.trips?.length">
      <dx-select-box
        *ngIf="employeesAvailable.length"
        width="150px"
        [dataSource]="employeesAvailable"
        [displayExpr]="employeeHelper.displayExpr"
        valueExpr="id"
        placeholder="Driver"
        (onValueChanged)="assignEmployeeHandler(group, altGroup, $event.value)">
      </dx-select-box>
    </ng-container>

    <span class="badge badge-with-remove" *ngIf="group.escort">
      Esc. {{ employeeHelper.displayExpr(group.escort) }}
      <button class="badge-remove-button" (click)="removeEscortHandler(group, altGroup)">
        <i class="fa fa-times"></i>
      </button>
    </span>
    <ng-container *ngIf="!group.escort && group.trips?.length">
      <dx-select-box
        *ngIf="employeesAvailable.length"
        width="150px"
        [dataSource]="employeesAvailable"
        [displayExpr]="employeeHelper.displayExpr"
        valueExpr="id"
        placeholder="Escort"
        (onValueChanged)="assignEscortHandler(group, altGroup, $event.value)">
      </dx-select-box>
    </ng-container>
  </div>
</ng-template>

<!--  Template -->
<ng-template #gridHeaderTemplate let-group="group" let-isPropose="isPropose" let-dataSourceIdx="dataSourceIdx">
  <div *ngIf="group?.load">
    <button *ngIf="isPropose" class="small-button" (click)="overwriteToManifestHandler(group, dataSourceIdx)">
      <i class="fa fa-arrow-left"></i>
    </button>
    <span class="badge">
      Travel {{ getHours(group.load.travelMinutes) }}h, {{ distanceInMiles(group.load.travelDistance) }}mi</span
    >
    <span class="badge">
      Loaded {{ getHours(group.load.loadedMinutes) }}h, {{ distanceInMiles(group.load.loadedDistance) }}mi</span
    >
    <span class="badge"> Number of Trips {{ group.load.tripsCount }}</span>
    <span
      class="badge"
      [ngClass]="{
        'badge-yellow': group.load.percent > 10 && group.load.percent <= 45,
        'badge-green': group.load.percent > 45
      }">
      Load {{ group.load.percent }}%</span
    >
    <span class="badge"> Free Time {{ getHours(group.load.freeMinutes) }}h </span>
    <button class="small-button" (click)="openRoutesMap(group)">
      <i class="fa fa-map"></i>
    </button>
  </div>
</ng-template>

<!--  Template -->
<ng-template #gridTemplate let-trips="trips" let-isPropose="isPropose">
  <dx-data-grid *ngIf="trips" [dataSource]="trips" [remoteOperations]="false" [errorRowEnabled]="false">
    <!-- [allowColumnReordering]="true"
    [rowDragging]="{ data: 'dragData1', group: 'shared', onAdd: onAdd }"> -->
    <dxo-pager [visible]="false"></dxo-pager>
    <dxo-search-panel [visible]="false"></dxo-search-panel>
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <dxo-filter-panel [visible]="false"></dxo-filter-panel>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxo-editing [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>
    <dxi-column cellTemplate="sequence" [width]="40" [allowSorting]="false"></dxi-column>
    <dxi-column
      dataField="lock"
      cellTemplate="checkbox"
      [visible]="!isPropose"
      [width]="40"
      [allowSorting]="false"></dxi-column>
    <dxi-column
      caption="Settings"
      [calculateDisplayValue]="getConsumerSettings"
      [allowSorting]="false"
      [width]="90"></dxi-column>
    <dxi-column caption="Consumer" [calculateDisplayValue]="getConsumerFullName" [allowSorting]="false"></dxi-column>
    <dxi-column
      caption="Appointment Time"
      dataField="at"
      dataType="date"
      format="shortTime"
      [allowSorting]="false"></dxi-column>
    <dxi-column
      caption="PU Time"
      cellTemplate="pickup"
      dataField="t"
      dataType="date"
      format="shortTime"
      [allowSorting]="false"></dxi-column>
    <dxi-column
      caption="DO Time"
      dataField="dot"
      dataType="date"
      format="shortTime"
      [allowSorting]="false"></dxi-column>
    <dxi-column caption="Origin" cellTemplate="address" dataField="o" [allowSorting]="false"></dxi-column>
    <dxi-column caption="Destination" cellTemplate="address" dataField="d" [allowSorting]="false"></dxi-column>

    <ng-container *dxTemplate="let cellInfo of 'checkbox'">
      <dx-check-box
        [(value)]="cellInfo.value"
        (onValueChanged)="cellInfo.data.lock = $event.value; lockChangeHandler()">
      </dx-check-box>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'sequence'">
      <span [class]="manifestTripsMap[cellInfo.data.id] ? 'badge-small' : 'badge-small badge-orange'">{{
        cellInfo.rowIndex + 1
      }}</span>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'pickup'">
      <span [innerHTML]="getPickupCell(cellInfo, trips)"></span>
    </ng-container>

    <ng-container *dxTemplate="let cellInfo of 'address'">
      <span [innerHTML]="getAddressCell(cellInfo)"></span>
    </ng-container>
  </dx-data-grid>
</ng-template>

<!--  Template -->
<ng-template #gridFooterTemplate let-group="group" let-isPropose="isPropose">
  <div *ngIf="!isPropose && showWorkTime && employeeWorkingTimeMap[group?.employee?.id]" class="grid-footer">
    <div>
      Weekly Hours before selected date:
      <b>{{ getHours(employeeWorkingTimeMap[group.employee.id].totalMinutesBeforeCurrent) }}h</b> (Estimated:
      <b>{{ getHours(employeeWorkingTimeMap[group.employee.id].totalMinutes) }}h</b>)
    </div>
  </div>

  <div *ngIf="isPropose && showWorkTime && group.load" class="grid-footer">
    <div>
      Proposed Work Start Time: <b>{{ group.startWorkingTime }}</b> (Shift Prep:
      <b>{{ group.startPrepareDuration }}min</b>, Travel from Base to the first PU:
      <b>{{ group.startTravelDuration }}min</b>)
    </div>
    <div>
      Finish Work Time: <b>{{ group.finishWorkingTime }}</b> (Travel to Base:
      <b>{{ group.finishTravelDuration }}min</b>, Shift Finish Time: <b>{{ group.finishPrepareDuration }}min</b>)
    </div>
    <div>
      Total Work Time: <b>{{ getHours(group.workingMinutes) }}h</b>
    </div>
    <div *ngIf="employeeWorkingTimeMap[group?.employee?.id]">
      Weekly Hours including selected date:
      <b>{{ getHours(employeeWorkingTimeMap[group.employee.id].totalMinutesBeforeCurrent + group.workingMinutes) }}h</b>
    </div>
  </div>
</ng-template>

