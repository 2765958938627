<app-content-w-header header="GMaps Log">
  <dx-data-grid
    class="content"
    height="100%"
    width="100%"
    [dataSource]="dso"
    [twoWayBindingEnabled]="false"
    [stateStoring]="grid_stateStoring"
    (onInitialized)="grid_onInitialized($event)"
    (onToolbarPreparing)="grid_onToolbarPreparing($event)">
    <dxo-editing mode="none" [allowAdding]="false" [allowUpdating]="false" [allowDeleting]="false"></dxo-editing>

    <dxo-paging [pageSize]="config.get('itemsPerPage', 50)"></dxo-paging>

    <dxo-export [enabled]="true" fileName="gmaps-log-export"></dxo-export>

    <dxi-column
      caption="Time of Exectuion"
      dataField="timestamp"
      dataType="date"
      format="shortDateShortTime"></dxi-column>
    <dxi-column caption="Duration of Execution (ms)" dataField="duration"></dxi-column>
    <dxi-column caption="User Name" [calculateDisplayValue]="username_calculateValue"></dxi-column>
    <dxi-column caption="Mode" dataField="mode"></dxi-column>
    <dxi-column caption="API Call From" dataField="caller"></dxi-column>
    <dxi-column caption="Price" dataField="price"></dxi-column>
    <dxi-column caption="API Call" dataField="params" [calculateDisplayValue]="params_calculateValue"></dxi-column>
    <dxi-column caption="API Result" dataField="params" [calculateDisplayValue]="result_calculateValue"></dxi-column>

    <!-- Templates -->
  </dx-data-grid>
</app-content-w-header>

