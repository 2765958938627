import { Pipe, PipeTransform } from '@angular/core';
import { Consumer, ConsumerView, Employee, EmployeeView, MyUserView, Person, PersonView } from '../../../sdk/models';
import { ConfigService } from '../../my-common/services/config.service';

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  constructor(private config: ConfigService) {}

  transform(value: any | Consumer | ConsumerView | Employee | EmployeeView | MyUserView, format?: string): string {
    format = format || (this.config.config && this.config.config.fullNameFormat) || '$F $L';
    let person: Person | PersonView;

    if (!value) {
      return '';
    } else if (value.person) {
      person = value.person;
    } else if (value.person_firstname || value.person_lastname) {
      person = Person.factory({
        id: value.person_id,
        firstname: value.person_firstname,
        lastname: value.person_lastname,
        dob: value.person_dob,
      });
    } else if (value.firstname || value.lastname) {
      person = value;
    }

    if (person) {
      return format
        .replace('$F', (person.firstname || '').trim())
        .replace('$L', (person.lastname || '').trim())
        .replace('$M', (person.middlename || '').trim())
        .replace('$N', (person.nickname || '').trim())
        .replace('$D', (person.dob || '').trim())
        .replace(/\s+/gi, ' ')
        .replace(/,+/gi, ',');
    }

    return '';
  }
}
