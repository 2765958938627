export const environment: Environment = {
  production: true,
  local: false,
  apiBaseUrl: window.ENV.APP_API_BASE_URL,
  apiRouterUrl: window.ENV.APP_API_ROUTER_URL,
  apiAltUrl: window.ENV.APP_API_ALT_URL,
  options: window.ENV.APP_OPTIONS,
  sentryDsn: window.ENV.APP_SENTRY_DSN,
  baseLocation: window.ENV.APP_BASE_LOCATION,
  churchLocation: window.ENV.APP_CHURCH_LOCATION,
};
