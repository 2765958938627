import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import Guid from 'devextreme/core/guid';
import moment from 'moment';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, takeUntil } from 'rxjs/operators';
import { headersAllTenantsAppend } from 'src/app/shared/classes/utils/utils';
import { ExtLoopBackAuth } from 'src/app/shared/modules/ext-sdk/services/ext-sdk-auth.service';
import { CommonService } from '../../../../shared/modules/my-common/services/common.service';
import { ConfigService } from '../../../../shared/modules/my-common/services/config.service';
import { DataSourceService } from '../../../../shared/modules/my-common/services/datasource.service';
import { ABaseComponent } from '../../../../shared/modules/ui/components/abstract/a-base.component';
import { GridHelperService } from '../../../../shared/modules/ui/services/grid-helper.service';
import { UiService } from '../../../../shared/modules/ui/services/ui.service';
import { Address, AddressApi, Consumer, ConsumerAddressView, ConsumerAddressViewApi, ConsumerApi, LoggerService, MyUserApi, MyUtils, MyUtilsApi, Phone, PhoneApi, TripManifest, } from '../../../../shared/sdk';
import { HelperService as ManifestHelperService } from '../../../trip-manifest/services/helper.service';
import { HelperService } from '../../services/helper.service';
export class MtmTripsComponent extends ABaseComponent {
    constructor(logger, ui, config, common, dss, helper, manifestHelper, gridHelper, userApi, auth) {
        super(logger);
        this.logger = logger;
        this.ui = ui;
        this.config = config;
        this.common = common;
        this.dss = dss;
        this.helper = helper;
        this.manifestHelper = manifestHelper;
        this.gridHelper = gridHelper;
        this.userApi = userApi;
        this.auth = auth;
        this.highlightDso = [
            { title: 'Today', value: [0, 0] },
            { title: 'Tomorrow', value: [1, 1] },
            { title: 'Next 7 Days', value: [0, 6] },
        ];
        this.highlight = [];
        this.showOnlyIssues = false;
        this.showOnlyUnreviewed = false;
        this.searchValue = '';
        this.searchSubject = new Subject();
        this.issuesCount = 0;
        this.unreviewedCount = 0;
        this.isToastVisible = false;
        this.toastMessage = '';
        this.toastType = 'info'; // can be 'info', 'warning', 'error', or 'success'
        // this.grid_stateStoring = this.sss.buildOptions('0c96aaaf-0959-4d8f-a4aa-321cea7bdd3e');
        this.grid_stateStoring = {
            enabled: true,
            type: 'localStorage',
            storageKey: '30c612ff-5616-470b-ae3b-fd94f5d9c1b8',
        };
        this.isSU$ = this.config.isSU$;
    }
    ngOnInit() {
        super.ngOnInit();
        this.searchSubscription = this.searchSubject
            .pipe(debounceTime(500), takeUntil(this.$onDestroy$))
            .subscribe(value => this.handleSearchChange(value));
    }
    showToast(message, type = 'info') {
        this.toastMessage = message;
        this.toastType = type;
        this.isToastVisible = true;
    }
    handleHighlightChange(e) {
        if (this.highlight.length) {
            if (this.startDate || this.endDate)
                this.startDate = this.endDate = null;
            this.buildDataSource();
        }
    }
    handleDateChange(e) {
        if (this.startDate && this.endDate) {
            if (this.highlight.length)
                this.highlight = [];
            this.buildDataSource();
        }
    }
    handleOnlyChange(e) {
        this.buildDataSource(false);
    }
    handleSearchChange(value) {
        console.log(value);
        this.searchValue = value;
        this.buildDataSource(false);
    }
    grid_onInitialized(e) {
        this.highlight = [this.highlightDso[0].value, this.highlightDso[1].value];
        this.gridHelper.handle(e.component, {
            flatToTreeObject: false,
            copyIdsOnSaving: false,
            selectRowOnEdit: false,
            notifyErrors: true,
        });
    }
    grid_onToolbarPreparing(e) { }
    grid_onContextMenuPreparing(e) {
        if (e.row && e.row.rowType === 'data') {
            const itmes = [];
            if (!e.row.data._reviewed)
                itmes.push({
                    text: 'Mark as Reviewed',
                    onItemClick: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        const res = yield this.dss
                            .getApi(MyUtils)
                            .updateExportDataCacheAsync(e.row.data._id, { _reviewed: true })
                            .pipe(catchError(err => of(undefined)))
                            .toPromise();
                        if (res) {
                            e.row.data._reviewed = res._reviewed;
                            this.buildDataSource(false);
                        }
                        else
                            this.showToast('Error marking as Reviewed', 'error');
                    }),
                });
            if (!e.row.data._invalid) {
                if (!e.row.data._assigned) {
                    if (e.row.data._isNew)
                        itmes.push({
                            text: 'Create Client and add trip to Manifest',
                            onItemClick: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                yield this.createConsumer(e.row.data);
                                yield this.addToManifest(e.row.data);
                                this.buildDataSource();
                            }),
                        });
                    else
                        itmes.push({
                            text: 'Add trip to Manifest',
                            onItemClick: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                yield this.addToManifest(e.row.data);
                                this.buildDataSource();
                            }),
                        });
                }
                if (e.row.data._addrMismatch || e.row.data._phoneMismatch) {
                    itmes.push({
                        text: 'Update Client Data',
                        onItemClick: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            if (e.row.data._addrMismatch) {
                                yield this.updateConsumerAddress(e.row.data);
                                e.row.data._addrMismatch = false;
                            }
                            if (e.row.data._phoneMismatch) {
                                yield this.updateConsumerPhone(e.row.data);
                                e.row.data._phoneMismatch = false;
                            }
                            this.buildDataSource();
                        }),
                    });
                }
            }
            e.items = itmes;
        }
    }
    grid_onCellPrepared(e) {
        if (e.rowType === 'data') {
            const commonFields = { _broker: 1, _date: 1, _tripId: 1 };
            const field = e.column.dataField;
            const { cls, title } = e.data._validationSummary[(commonFields[field] && 'common') || field] || {};
            const { crossed } = e.data._validationSummary[field] || {};
            if (crossed)
                e.cellElement.style.textDecoration = 'line-through';
            if (cls) {
                if (cls['cell-danger'])
                    e.cellElement.classList.add('cell-danger');
                else if (cls['cell-warning'])
                    e.cellElement.classList.add('cell-warning');
                else if (cls['cell-yellow'])
                    e.cellElement.classList.add('cell-yellow');
            }
            if (title)
                e.cellElement.title = title;
        }
    }
    ngAfterViewInit() { }
    loadConsumerAndManfiest(doc, date) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield Promise.all([
                this.dss
                    .getApi(ConsumerAddressView)
                    .findOne({
                    where: {
                        and: [
                            { person_lastname: { like: `%${doc._lastname}%` } },
                            { person_firstname: { like: `%${doc._firstname}%` } },
                            {
                                or: [
                                    { contact_addresses_0_zip: { like: `%${doc['Pickup Zip Code']}%` } },
                                    { contact_addresses_0_zip: { like: `%${doc['Delivery Zip Code']}%` } },
                                ],
                            },
                        ],
                    },
                }, headersAllTenantsAppend)
                    .toPromise(),
                this.manifestHelper.api.getCurrentManifest(date).toPromise(),
            ]);
        });
    }
    addToManifest(doc) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const date = moment.utc(doc._date).format('YYYY-MM-DD');
            const [consumer, manifest] = yield this.loadConsumerAndManfiest(doc, date);
            const tId = doc._tripId;
            const isReturn = tId[tId.length - 1] === 'B';
            const adc = isReturn ? 'Pickup' : 'Delivery';
            const addr = `${doc[`${adc} Address`]}, ${doc[`${adc} City`]}, ${doc[`${adc} State`]}, ${doc[`${adc} Zip Code`]}`;
            const short = (doc._facility && doc._facility.short) || addr;
            const [o, d] = isReturn ? [short, 'RESIDENCE'] : ['RESIDENCE', short];
            const [c, at] = [consumer.id, doc['Appointment Time']];
            const [b, st, rt, rid] = ['MTM', 'AMB_TRIP', false, new Guid().toString()];
            const data = [{ at, s: 0, rt, rid, o, d, b, st, c, tId }];
            if (manifest && manifest.id)
                yield this.manifestHelper.api.safeSaveRecord(manifest.id, data[0]).toPromise();
            else
                yield this.manifestHelper.api
                    .create(new TripManifest({ requestID: new Guid().toString(), date, data }))
                    .toPromise();
        });
    }
    createConsumer(doc) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (doc._consumer && doc._consumer.id)
                return doc._consumer;
            const tenantId = this.auth.getCurrentTenant();
            const isReturn = doc._tripId[doc._tripId.length - 1] === 'B';
            const home = isReturn ? 'Delivery' : 'Pickup';
            return yield this.dss
                .getApi(Consumer)
                .myCreateWithRelated({
                status: 'PENDING',
                tenantId,
                mci: doc._mci,
                person: {
                    firstname: doc._firstname,
                    lastname: doc._lastname,
                    contact: {
                        addresses: [
                            {
                                street: doc[`${home} Address`],
                                city: doc[`${home} City`],
                                state: doc[`${home} State`],
                                zip: doc[`${home} Zip Code`],
                            },
                        ],
                        phones: [{ value: doc["Member's Phone Number"] }],
                    },
                },
            })
                .toPromise();
        });
    }
    updateConsumerAddress(doc) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // if (doc._consumer._contact.addresses_0_id)
            //   await this.dss.getApi<AddressApi>(Address).deleteById(doc._consumer._contact.addresses_0_id).toPromise();
            const isReturn = doc._tripId[doc._tripId.length - 1] === 'B';
            const home = isReturn ? 'Delivery' : 'Pickup';
            yield this.dss
                .getApi(Address)
                .create({
                contactId: doc._consumer._contact.id,
                street: doc[`${home} Address`],
                city: doc[`${home} City`],
                state: doc[`${home} State`],
                zip: doc[`${home} Zip Code`],
            })
                .toPromise();
        });
    }
    updateConsumerPhone(doc) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // if (doc._consumer._contact.phones_0_id)
            //   await this.dss.getApi<PhoneApi>(Phone).deleteById(doc._consumer._contact.phones_0_id).toPromise();
            yield this.dss
                .getApi(Phone)
                .create({
                contactId: doc._consumer._contact.id,
                value: doc["Member's Phone Number"],
            })
                .toPromise();
        });
    }
    filter(docs) {
        return docs.filter(doc => {
            return ((!this.showOnlyIssues || doc._validationSummary.common.title.length) &&
                (!this.showOnlyUnreviewed || (doc._validationSummary.common.title.length && !doc._reviewed)) &&
                (!this.searchValue ||
                    Object.values(doc).some(v => v &&
                        ['string', 'number'].includes(typeof v) &&
                        ('' + v).toLowerCase().includes(this.searchValue.toLowerCase()))));
        });
    }
    buildDataSource(buildData = true) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (buildData) {
                try {
                    this.grid.instance.beginCustomLoading('Loading...');
                    const [start, end] = (this.highlight.length
                        ? this.highlight
                            .reduce((p, [s, e]) => [Math.min(p[0], s), Math.max(p[1], e)], this.highlight[0])
                            .map(n => moment().add(n, 'day'))
                        : [moment(this.startDate), moment(this.endDate)]).map(m => m.format('YYYY-MM-DD'));
                    const docs = yield this.dss.getApi(MyUtils).getMTMTrips(start, end).toPromise();
                    this.issuesCount = 0;
                    this.unreviewedCount = 0;
                    docs.forEach(doc => {
                        this.issuesCount += +!!doc._validationSummary.common.title.length;
                        this.unreviewedCount += +(!!doc._validationSummary.common.title.length && !doc._reviewed);
                    });
                    this.docs = docs;
                }
                catch (err) {
                    this.showToast('Error loading data', 'error');
                }
                finally {
                    this.grid.instance.endCustomLoading();
                }
            }
            this.dso = this.filter(this.docs);
        });
    }
}
